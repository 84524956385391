import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from 'reactstrap'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import Button from '../partials/Button'
import css from './GroupForm.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { useProfile } from '../../../../context/MainContext'
import { deleteUser, getUsersByGroup } from '../../../../service/module/Groups'

export default function GroupForm({
  message,
  title,
  changeInput,
  register,
  errors,
  update,
  roles,
  group,
  users,
  changed, 
  setUsers,
  modalities
}) {

  const history = useHistory()
  const location = useLocation()
  const [messageRemove, setMessageRemove] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [notification, setNotification] = React.useState()
  const [isUserGroupDeleted, setIsUserGroupDeleted] = React.useState()
  const { userId } = useProfile()
  const [roleName, setRoleName] = React.useState(null)
  const [roleSeniority, setRoleSeniority] = React.useState(null)
  const [modalitiyName, setModalityName] = React.useState(null)
  
  useEffect(() => {
    if (roleName && roleSeniority && roles && roles.length > 0) {
      const role = roles.find(item => item.name === roleName && item.seniority === roleSeniority)
      if (role) {
        changeInput({
          target: {
            name: 'role_id',
            value: role.id
          }
        })
      }
    }
  }, [roleName, roleSeniority, roles])


  useEffect(() => { 
    if(modalitiyName && modalities && modalities.length > 0){ 
      const modality = modalities.find(item => item.modality === modalitiyName)
      changeInput({
        target: { 
          name: 'modality_id',
          value: modality.id
        }
      })
    }
  },[modalitiyName, modalities])

  useEffect(() => {
    if (group && group.role_id && roles && roles.length > 0) {
      const role = roles?.find(item => item.id === group.role_id)
      if (role) {
        setRoleName(role.name)
        setRoleSeniority(role.seniority)
      }
    }

    if(group && group.modality_id && modalities && modalities.length > 0){ 
      const modality = modalities?.find(item => item.id === group.modality_id)
      if(modality) { 
        setModalityName(modality.modality)
      }
    }
  }, [group, roles, modalities])

  React.useEffect(() => { 
    if(location.message){ 
      setMessageRemove(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  React.useEffect(() => {
    async function getUsers(id){ 
      await getUsersByGroup(id)
      .then(response => {
        setUsers(response.data)
      })
      .catch((error) => console.error(error))
    }

    group.id !== undefined && getUsers(group.id)
    
  }, [group.id, isUserGroupDeleted])

  async function deleteUserInGroup(userGroupId){ 
    await deleteUser(userGroupId, {"created_by": userId})
    .then(response => { 
      setMessageRemove('Usuario eliminado correctamente')
      setColorMessage('success')
      setNotification('Éxito')
      setIsUserGroupDeleted(!isUserGroupDeleted)
    })
    .catch(error => { 
      setMessageRemove(error.message)
      setColorMessage('danger')
      setNotification('Error')
    })

  }
  
  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
      icon: css.warningIcon,
      icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id){

    swalCustom.fire({
      title: '¿Estás seguro que deseas quitar este usuario?',
      iconHtml: '<div className="' + css.warningIcon +'"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Quitar usuario',
    }).then(_result => {
      if (_result.isDenied) {
        deleteUserInGroup(id)
      }
    })
  }


  return (
    <div className={css.root}>
      {messageRemove && colorMessage && (
        <Alert color={colorMessage}>
          <strong> ¡{notification}! </strong> {messageRemove}
        </Alert>
      )}
      {message && typeof message === 'object' ? (
        <Alert color="danger">
          <strong>¡Ocurrio un error! |</strong>
        </Alert>
      ) : message ? (
        <Alert color="success">
          <strong>{update ? '¡Actualización exitosa!' : 'Registro exitoso'}</strong>
        </Alert>
      ) : null}
      <div className={classNames(css.tableContainer, css.darkTableContainer)}>
        <div className={css.tableHeader}>
          <h3 className={css.title}>{title}</h3>
        </div>
        <form className={css.form}>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group-name">
                Nombre
              </label>
              <input
                invalid={errors && errors.group_name ? true : false}
                onChange={changeInput}
                name="group_name"
                id="input-group-name"
                defaultValue={group && group.group_name}
                className={css.input}
                type="text"
              />
              {errors && errors.group_name && (
                <div className={css.feedback}>{errors.group_name}</div>
              )}
            </div>

            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group-modality">
                Modalidad
              </label>
              <select
                invalid={errors && errors.role_id ? true : false}
                name="group_modality"
                onChange={(e) => setModalityName(e.target.value)}
                type="select"
                id="input-modality"
                className={css.input}
                defaultValue={(group && group.modality_id) || ''}
                disabled={update ? true : false}
              >
                <option value="" sabled>
                  Selecciona una modalidad
                </option>
                {modalities &&
                modalities.map ((item, key) => { 
                  return ( 
                    <option value={item.modality} key={key} selected={ modalitiyName === item.modality}>
                      {item.modality}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group-role-name">
                Perfil
              </label>
              <select
                invalid={errors && errors.role_id ? true : false}
                name="role_name"
                onChange={(e) => setRoleName(e.target.value)}
                type="select"
                defaultValue={(group && group.role_id) || ''}
                id="input-role-name"
                className={css.input}
                disabled={update ? true : false}
              >
                <option value="" disabled>
                  Selecciona un perfil
                </option>
                {roles &&
                  roles.map((item, key) => {
                    return (
                      <option value={item.name} key={key} selected={roleName === item.name}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
              {errors && errors.role_id && <div className={css.feedback}>{errors.role_id}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group-role-seniority">
                Seniority
              </label>
              <select
                invalid={errors && errors.role_id ? true : false}
                name="role_seniority"
                onChange={(e) => setRoleSeniority(e.target.value)}
                type="select"
                defaultValue={(group && group.role_id) || ''}
                id="input-role-seniority"
                className={css.input}
                disabled={update ? true : false}
              >
                <option value="" disabled>
                  Selecciona un seniority
                </option>
                {roleName &&
                  roles &&
                  roles.map((item, key) => {
                    if (item.name !== roleName) {
                      return null
                    }
                    return (
                      <option
                        value={item.seniority}
                        key={key}
                        selected={roleSeniority === item.seniority}
                      >
                        {item.seniority}
                      </option>
                    )
                  })}
              </select>
              {errors && errors.role_id && <div className={css.feedback}>{errors.role_id}</div>}
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-assessment">
                Evaluación final
              </label>
              <input
                invalid={errors && errors.assessment ? true : false}
                onChange={changeInput}
                name="assessment"
                id="input-assessment"
                defaultValue={group && group.assessment !== 'null' ? group.assessment : ' '}
                className={css.input}
                type="text"
              />
              {errors && errors.assessment && (
                <div className={css.feedback}>{errors.assessment}</div>
              )}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label">Estatus</label>
              <div className={css.radioGroup}>
                <div className={css.radioHolder}>
                  <input
                    type="radio"
                    name="group_status"
                    id="input-group-status-true"
                    onChange={changeInput}
                    checked={group && group.group_status === 1 ? true : false}
                    className={css.radio}
                  />
                  <label htmlFor="input-group-status" className={css.radioLabel}>
                    Activo
                  </label>
                </div>

                <div className={css.radioHolder}>
                  <input
                    type="radio"
                    name="group_status"
                    id="input-group-status-false"
                    onChange={changeInput}
                    checked={group && group.group_status === 0 ? true : false}
                    className={css.radio}
                  />
                  <label htmlFor="input-group-status" className={css.radioLabel}>
                    Inactivo
                  </label>
                </div>
              </div>
              {errors && errors.group_status && (
                <div className={css.feedback}>{errors.group_status}</div>
              )}
            </div>
          </div>
          <div className={css.tableContainer}>
            <div className={css.tableHeader}>
              <h3 className={css.title}> Personas asignadas</h3>
            </div>

            <div className={css.table}>
              <thead className={css.thead}>
                <tr className={css.tr}>
                  <th scope="col" className={css.th}>
                    Correo
                  </th>
                  <th scope="col" className={css.th}>
                    Nombre
                  </th>
                  <th scope="col" className={css.th}>
                    Apellido(s)
                  </th>
                  <th scope="col" className={css.th}>
                    Perfil
                  </th>
                  <th scope="col" className={css.th}>
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody className={css.tbody}>
                {users &&
                  users.map((item, key) => {
                    return (
                      <tr className={css.tr} key={key}>
                        <td scope="row" className={css.td}>
                          {item.email}
                        </td>

                        <td scope="row" className={css.td}>
                          {item.names}
                        </td>

                        <td scope="row" className={css.td}>
                          {item.last_name}
                        </td>

                        <td scope="row" className={css.td}>
                          {item.role}
                        </td>

                        <td
                          className={classNames(css.td, css.actionButtons)}
                          style={{ textAlign: 'center' }}
                        >
                          <Button
                            isicon={true}
                            className={css.delete}
                            type="button"
                            title="Quitar"
                            onClick={() => toggleModal(item.id)}
                          >
                            <span>
                              <FontAwesomeIcon icon={faXmark} />
                            </span>
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </div>
            <div className={css.tableFooter}>
              {!users ||
                (users?.length === 0 && (
                  <div className={css.noData}>
                    <p>Sin aprendedores asignados</p>
                  </div>
                ))}
            </div>
          </div>

          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group-description">
                Descripción
              </label>
              <textarea
                invalid={errors && errors.group_description ? true : false}
                onChange={changeInput}
                name="group_description"
                id="input-group-description"
                defaultValue={
                  group && group.group_description !== 'null' ? group.group_description : ''
                }
                className={classNames(css.input, css.textarea)}
                type="text"
                rows="5"
              />
              {errors && errors.group_description && (
                <div className={css.feedback}>{errors.group_description}</div>
              )}
            </div>
          </div>
        </form>
        <div className={css.tableFooter}>
          <nav aria-label="...">
            <div className={css.pagination}>
              <Button
                className={css.delete}
                type="button"
                onClick={() => {
                  history.push('/dashboard/groups')
                }}
              >
                Cancelar
              </Button>
              {register && (
                <Button type="button" onClick={register} disabled={changed}>
                  Registrar
                </Button>
              )}
              {update && (
                <Button type="button" onClick={update} disabled={changed}>
                  Actualizar
                </Button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

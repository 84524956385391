import React from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'moment/locale/es';
import { Pagination, PaginationItem, PaginationLink, Alert} from 'reactstrap'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faAngleRight, faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/modules/dashboard/partials/Button'
import Header from '../../components/modules/dashboard/partials/Header'
import SearchInput from '../../components/modules/dashboard/partials/SearchInput'
import { getAllGroups, deleteGroupURL, removeGroupById, getFindGroup } from '../../service/module/Groups'
import { getAllRoles } from '../../service/module/Roles'
import { useProfile } from '../../context/MainContext'
import css from './Groups.module.css'
import { getAllModalities } from '../../service/module/Modalities'

export default function Groups() {
  const [message, setMessage] = React.useState()
  const [list, setList] = React.useState()
  const [roles, setRoles] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [notification, setNotification] = React.useState()
  const [meta, setMeta] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [isGroupDeleted, setIsGroupDeleted] = React.useState()
  const [pages, setPages] = React.useState()
  const { userType, userId } = useProfile()
  const [word, setWord] = React.useState('')
  const history = useHistory()
  const location = useLocation()
  const actionsEnabled = true
  const [modalities, setModalities] = React.useState()

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  async function getRoles() {
    await getAllRoles()
      .then(response => {
        setRoles(response.data?.message ? [] : response.data)
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  async function getModalities(){ 
    await getAllModalities()
    .then(response => { 
      setModalities(response.data)
    })
    .catch(err => { 
      console.error(err.message)
    })
  }


  React.useEffect(() => {
    async function getGroups(current) {
      await getAllGroups(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    async function getFindGroups(word) {
      await getFindGroup(word, currentPage)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (!word || word === '') {
      getGroups(currentPage)
    } else {
      getFindGroups(word, currentPage)
    }

    getRoles()
    getModalities()
  }, [currentPage, isGroupDeleted])

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage(meta.next_page_url)
    }
  }

  async function removeGroup(groupId){ 
    await removeGroupById(groupId, {"created_by": userId})
    .then(response => { 
      setMessage('Grupo eliminado correctamente.')
      setColorMessage('success')
      setNotification('Éxito')
      setIsGroupDeleted(!isGroupDeleted)
    })
    .catch(error => { 
      setMessage(error.message)
      setColorMessage('danger')
      setNotification('Error')
    })
  }

  async function deleteGroup(groupId) {
    await deleteGroupURL(groupId, {created_by: userId})
      .then(response => {
        setMessage('Grupo eliminado correctamente')
        setColorMessage('success')
        setNotification('Éxito')
        setIsGroupDeleted(!isGroupDeleted)
      })
      .catch(error => {
        setMessage(error.message)
        setColorMessage('danger')
        setNotification('Error')
      })
  }
  
  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id) {    
    swalCustom.fire({
      title: '¿Estás seguro que deseas eliminar este grupo?',
            iconHtml: '<div className="' + css.warningIcon +'"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Eliminar grupo',
    }).then(_result => {
      if (_result.isDenied) {
        deleteGroup(id)
      }
    })
  }

  const getGroupRole = (id) => {
    const role = roles?.find(role => role.id === id)
    return role ? role.name + ' ' + role.seniority : ''
  }

  function search() {
    async function getFindGroups(word) {
      await getFindGroup(word)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }
  
    getFindGroups(word)
  }

  const getModalityName = (id) => { 
    const modality = modalities?.find(modality => modality.id === id)
    return modality ? modality.modality : ''
  }

  React.useEffect(() => {
    async function getGroups(current) {
      await getAllGroups(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    if (word.length > 2) {
      search()
    }

    if (word.length === 0) {
      getGroups('?page=1')
    }
  }, [word])

  return (
    <div>
      <Header />
      {/* Page content */}
      <div className={classNames(css.root)}>
        {message && colorMessage && (
          <Alert color={colorMessage}>
            <strong>¡{notification}!</strong> {message}
          </Alert>
        )}
        <div className={css.tableContainer}>
          <div className={css.tableHeader}>
            <h3 className="mb-0">Lista de Grupos</h3>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              <SearchInput onSearch={setWord} placeholder="Buscar nombre" />
            </div>
            {userType === 'operative' && (
              <Button
                newButton={true}
                href="/dashboard/users/add"
                className={css.addButton}
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/dashboard/groups/add')
                }}
              >
                Agregar
              </Button>
            )}
          </div>
          <div className={css.table}>
            <table className={css.table}>
              <thead className={css.thead}>
                <tr className={css.tr}>
                  <th scope="col" className={css.th}>Estatus</th>
                  <th scope="col" className={css.th}>Nombre</th>
                  <th scope="col" className={css.th}>Perfil completo</th>
                  <th scope="col" className={css.th}>Última asignación</th>
                  <th scope='col' className={css.th}> Modalidad </th>
                  <th scope="col" className={css.th} style={{textAlign: "center"}}>Personas asignadas</th>
                  {userType === 'operative' && actionsEnabled && <th scope="col" className={css.th} style={{textAlign: "center"}}>Acciones</th>}
                </tr>
              </thead>
              <tbody className={css.tbody}>
                {list &&
                  list.map((item, key) => {
                    return (
                      <tr className={css.tr} key={key}>
                        <td scope="row" className={css.td}>
                          <span 
                            className={classNames(css.status, {
                              [css.active]: item.group_status === 1,
                              [css.inactive]: !item.group_status || item.group_status === 0
                            })}
                          >
                            {item.group_status === 1 ? "ACTIVO": "INACTIVO"}
                          </span>
                        </td>
                        <td scope="row" className={css.td}>
                          <span className={css.name}>{item.group_name}</span>
                        </td>
                        <td scope="row" className={css.td}>
                          <span>{(getGroupRole(item.role_id) || "N/A") + " / " + (item.reference_id || item.id)}</span>
                        </td>
                        <td className={css.td}>
                          <span>{item.updated_at ? moment(item.updated_at).locale('es').format('LLL') : moment(item.created_at).locale('es').format('LLL')}</span>
                        </td>
                        <td className={css.td}>
                          <span> {getModalityName(item.modality_id)}</span>
                        </td>
                        <td className={css.td} style={{textAlign: "center"}}>
                          <span>{item.members}</span>
                        </td>
                        {userType === 'operative' && actionsEnabled && (
                          <td className={classNames(css.td, css.actionButtons)}>
                            <Button
                              isicon={true}
                              className={css.edit}
                              type="button"
                              title='Editar'
                              onClick={() => {
                                history.push(`/dashboard/groups/${item.id}/edit`)
                              }}
                            >
                              <span>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Button>
                            
                              <Button
                                isicon={true}
                                className={css.delete}
                                type="button"
                                title='Eliminar'
                                onClick={() => {toggleModal(item.id)}}
                                disabled = {item && item.members >= 1 ? true : false}
                              >
                                <span>
                                  <FontAwesomeIcon icon={faTrash} />
                                </span>
                              </Button>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div className={css.tableFooter}>
            {list && list.length > 0 ? (
              <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                {meta && (
                  <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                    <PaginationLink
                      href={meta.first_page_url}
                      onClick={previous}
                      tabIndex={!meta.previous_page_url ? '-1' : undefined}
                    >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
                {pages &&
                  pages.map((item, key) => {
                    /*
                     Always show 9 pages (if there are more than 9)
                     show 4 pages before and 4 pages after the current page
                    */
                    if (meta.current_page <= 5 && key < 9) {
                      return (
                        <PaginationItem
                          key={key}
                          className={meta.current_page === item ? 'active' : ''}
                        >
                          <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                            {item}
                            {meta.current_page === item && (
                              <span className="sr-only">(current)</span>
                            )}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    }

                    if (meta.current_page > 5) {
                      if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    }

                    if (meta.last_page - meta.current_page < 5) {
                      if (key > meta.last_page - 10) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    }

                  })}
                {meta && (
                  <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                    <PaginationLink href={meta.last_page_url} onClick={next}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </nav>
            ) : (
              <div className={css.noData}>
                <span>Sin grupos</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

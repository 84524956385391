import React from 'react';

const useDynamicTitle = (challengeSequence, maxSequenceVal) => {
    const [dynamicTitle, setDynamicTitle] = React.useState(null);

    // Titles for cards 1 to 3
    const starterCardsMap = new Map([
        [1, "Introducción"],
        [2, "Configuración"],
        [3, "Tarjeta"]
    ])

    // Get starter card itle
    const getStarterCards = (seq) => {
        return starterCardsMap.get(seq)
    }

    // Helper function to determine the dynamic title
    const determineTitle = (sequence, maxSequence) => {
        if (sequence < 4) {
            setDynamicTitle(getStarterCards(sequence));
        } else if (sequence === maxSequence || sequence === 4) {
            setDynamicTitle("Diagnóstico");
        } else {
            setDynamicTitle("Reto " + (sequence - 4));
        }
    }

    // Determine title on render
    React.useEffect(() => {
        // Early return
        if(maxSequenceVal < 6) return setDynamicTitle("Tarjeta")
        // Expected flow
        determineTitle(challengeSequence, maxSequenceVal)
    } ,[])

    return { dynamicTitle }

  };
  
  export default useDynamicTitle;
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Header from '../../components/modules/dashboard/partials/Header'
import { showUser, updateUser } from '../../service/module/Users'
import UserForm from '../../components/modules/dashboard/users/UserForm'
import { useProfile } from '../../context/MainContext'
import Swal from 'sweetalert2'
import css from './AddGroup.module.css'
import { ConstructionOutlined } from '@mui/icons-material'

export default function EditUser() {
  const { id } = useParams()
  const [user, setUser] = React.useState({
    email: null,
    names: null,
    last_name: null,
    sur_name: null,
    birthdate: null,
    gender: 'female',
    user_type: 'learner',
  })

  const [errors, setErrors] = React.useState()
  const [message, setMessage] = React.useState()
  const [isUserUpdated, setIsUserUpdated] = React.useState()
  const { userId, userType } = useProfile()
  const history = useHistory()
  const [userTemp, setUserTemp] = React.useState({
    email: null,
    names: null,
    last_name: null,
    sur_name: null,
    birthdate: null,
    gender: 'female',
    user_type: 'learner',
  })

  const [changed, setChanged] = React.useState(true)

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() =>{ 
    isEqual(user, userTemp)
  }, [user, userTemp])

  React.useEffect(() => {
    async function getUser(id) {
      await showUser(id)
        .then(response => {
          const userFilled = response.data[0]
          setUser({
            ...userFilled,
            profile: null,
            ...userFilled.profile,
          })

          setUserTemp({
            ...userFilled,
            profile: null,
            ...userFilled.profile
          })
        })
        .catch(() => {})
    }

    getUser(id)
  }, [id, isUserUpdated])

  function changeInput(e) {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
    isEqual(user, userTemp)

  }

  function changeDate(e) {
    setUser({
      ...user,
      birthdate: e.format('yyyy-MM-DD'),
    })
    isEqual(user, userTemp)
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function update() {
    user.userId = userId
    user.gender = 'female'
    await updateUser(id, user)
      .then(response => {
        setIsUserUpdated(true)
        setMessage(response.message)
        if (response?.success === true) {
          swalCustom.fire({
            title: 'Usuario actualizado exitosamente!',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/users`)
          })
        } else {
          swalCustom.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/users`)
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/users`)
        })
      })
  }

  function isEqual(user, userTemp){ 
    if(user.email !== userTemp.email){
      setChanged(false)
    }else if(user.user_type !== userTemp.user_type){
      setChanged(false)
    }else{
      setChanged(true)
    }
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <UserForm
          message={message}
          user={user}
          update={update}
          changeInput={changeInput}
          changeDate={changeDate}
          errors={errors}
          title="Información del usuario"
          changed={changed}
        />
      )}
    </div>
  )
}

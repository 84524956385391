import React from "react";

import { useParams, useLocation, useHistory } from "react-router-dom";
import { $generateHtmlFromNodes } from '@lexical/html'
import Swal from "sweetalert2";

import Header from "../../components/modules/dashboard/partials/Header";
import SprintForm from "../../components/modules/dashboard/sprints/SprintForm";
import Button from '../../components/modules/dashboard/partials/Button'
import { Modal } from '../../components'
import { useProfile } from "../../context/MainContext";
import {
  getSprintsResources,
} from "../../service/module/Challenges";
import { updateModality, getModalityById,updateTitleModality, getAllModalities } from "../../service/module/Modalities";

import css from "./EditSprint.module.css";

export default function EditSprintChild() {
  const { id, idSprint, modalityId, idChild } = useParams();
  const [message, setMessage] = React.useState();
  const [sprint, setSprint] = React.useState({});
  const [errors, setErrors] = React.useState();
  const [metaResources, setMetaResources] = React.useState();
  const [listResources, setListResources] = React.useState();
  const [pagesResources, setPagesResources] = React.useState();
  const [currentPageResources, setCurrentPageResources] = React.useState("");
  const { userType, userId} = useProfile();
  const location = useLocation();
  const history = useHistory();
  const editorRef = React.useRef({
    content: null
  })
  const [initialModality, setInitialModality] = React.useState(null)
  const [activeModality, setActiveModality] = React.useState(null)
  const [showAlertModal, setShowAlertModal] = React.useState(false)
  const [titleSprint, setTitleSprint] = React.useState('')
  const [enableEditName, setEnableEditName] = React.useState(false)
  const [modalityLabel, setModalityLabel] = React.useState()

  React.useEffect(() => { 
    async function getModalities(modalityId){ 
      await getAllModalities()
      .then(response => { 
        getLabel(response.data, modalityId)
      })
      .catch(() => { })
    }

    getModalities(activeModality?.modality_id)
  },[activeModality])

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message);
    }
  }, [location]);

  React.useEffect(() => {
    async function getResources(current) {
      await getSprintsResources(current, idChild)
        .then((response) => {
          setListResources(response.data);
          setMetaResources(response.meta);
          setPagesResources(
            range(response.meta.first_page, response.meta.last_page)
          );
        })
        .catch(() => {});
    }
    getResources(currentPageResources);
  }, [currentPageResources, idChild]);

  function range(start, end) {
    var foo = [];
    for (var i = start; i <= end; i++) {
      foo.push(i);
    }
    return foo;
  }

  React.useEffect(() => {
    getModality(idChild);
  }, [idChild]);

  async function getModality(id) {
    await getModalityById(id)
      .then((response) => {
        setTitleSprint(response?.data[0] ? response.data[0].tittle_modality : 'Editar sprint hijo')
        const sprintFilled = response.data?.message ? {} : response.data[0];
        setActiveModality({
          ...sprintFilled,
        });
      })
      .catch(() => {});
  }

  const generateHtml = () => {
    const editor = editorRef.current;
    if (editor) {
      let _html = null;
      let _json = null;

      editor.update(() => {
        _html = $generateHtmlFromNodes(editor, null);
        const editorState = editor.getEditorState();
        _json = JSON.stringify(editorState);
      });

      if (_html === '<p class="editor-paragraph"><br></p>') {
        return null;
      }

      return {
        _html: _html,
        _json: _json,
      };
    } else {
      return null;
    }
  };

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })
  
  const getValues = () => {
    var sprintKeys = Object.keys(activeModality)
    var newSprint = {}
    for (var i = 0; i < sprintKeys.length; i++) {
      const input = document.getElementById(`${sprintKeys[i]}`)
      if (input) {
        newSprint[sprintKeys[i]] = input.value

        if (input.name === 'content_type') {
          if (input.checked) {
            newSprint[sprintKeys[i]] = 'parent'
          } else {
            newSprint[sprintKeys[i]] = 'research'
          }
        }

        if (input.name === 'action_url') {
          if (/(http(s?)):\/\//i.test(input.value)) {
            newSprint[sprintKeys[i]] = input.value
          } else {
            newSprint[sprintKeys[i]] = `https://${input.value}`
          }
        }

        if (sprintKeys[i] === 'allow_survey') {
          newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]] === 'true' || activeModality[sprintKeys[i]] === 1 ? 1 : 0
        }
      } else {
        newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]]

        if (sprintKeys[i] === 'allow_survey') {
          newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]] === 'true' || activeModality[sprintKeys[i]] === 1 ? 1 : 0
        }
      }
    }

    return newSprint
  }

  async function update() {
    const newModality = getValues()
    const generatedEditorContent = generateHtml()
    newModality.content = generatedEditorContent?._html || sprint.content || ''
    newModality.content_type = 'research'

    await updateModality(idChild, newModality)
      .then((response) => {
        if(response.success){
          setInitialModality(newModality)
          swalCustom
              .fire({
                title: '¡Modalidad actualizada exitosamente!',
                icon: 'success',
                showDenyButton: true,
                showConfirmButton: true,
                denyButtonText: 'Seguir editando',
                confirmButtonText: 'Regresar al sprint padre',
              })
              .then(result => {
                if (result.isConfirmed) {
                  history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
                }
              })
        }
      })
      .catch((error) => {
        setErrors(error.errors);
      });
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0

        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({ ...errors, [e.target.name]: null })
        })
      }

      e.target.value = Math.round(e.target.value)
    }

    setActiveModality({ ...activeModality, [e.target.name.replace('', '')]: e.target.value })
  }

  function changeCheckbox(e, isChecked) {
    e.target.value = isChecked;
    changeInput(e);
  }

  const compareChanges = (usedModality, activeCopy) => {
    // Check if the modality has been changed
    // Remove updated_at and created_at from the modality to compare
    delete usedModality?.updated_at
    delete usedModality?.created_at
    delete usedModality?.updated_by

    const parser = new DOMParser();
    const doc = parser.parseFromString(usedModality.content, 'text/html');

    // Function to remove attributes and classes recursively
    function removeAttributesAndClasses(element) {
      const attributes = Array.prototype.slice.call(element.attributes)
      if (attributes?.length > 0) {
        attributes.forEach((attr) => {
          if (attr.name.toLowerCase() === 'class' || attr.name.toLowerCase() === 'value' || attr.name.toLowerCase() === 'dir') {
            element.removeAttribute(attr.name);
          }
        })
      }

      for (const child of element.children) {
        removeAttributesAndClasses(child);
      }
    }

    // Remove attributes and classes from the HTML content
    removeAttributesAndClasses(doc.body);

    // Get the innerHTML of the modified content
    const modifiedContent = doc.body.innerHTML;

    usedModality.content = modifiedContent

    const generatedEditorContent = generateHtml() // Generate the html of the editor

    // Remove updated_at and created_at from the modality copy to compare
    delete activeCopy.updated_at
    delete activeCopy.created_at
    delete activeCopy.updated_by

    const docActive = parser.parseFromString(generatedEditorContent._html, 'text/html');
    // Remove attributes and classes from the HTML content
    removeAttributesAndClasses(docActive.body);

    // Get the innerHTML of the modified content
    const modifiedContentActive = docActive.body.innerHTML;

    activeCopy.content = modifiedContentActive

    return usedModality.content !== activeCopy.content // Check if the modality has changed
  }

  const validateChanges = (source) => {
 
    let activeCopy = {} // Copy the active modality
    const usedModality = initialModality // Find the modality in the sprint
    activeCopy = { ...activeModality }
    let modalityHasChanged = false
    const modalityContentHasChanged = compareChanges(usedModality, activeCopy)

    if(modalityContentHasChanged){ 
      modalityHasChanged = true;
    }else if(parseInt(usedModality.minutes) && parseInt(usedModality.minutes) !== parseInt(activeCopy.minutes)){ 
      modalityHasChanged = true
    }else if(usedModality.allow_survey !== activeCopy.allow_survey){
      modalityHasChanged = true
    }else if(usedModality.action_url !== activeCopy.action_url && activeCopy.banner !== null && activeCopy.banner !== undefined && activeCopy.banner !== 'null'){
      modalityHasChanged = true
    }else if(usedModality.banner !== activeCopy.banner && activeCopy.banner !== '' && activeCopy.banner !== null && activeCopy.banner !== undefined && activeCopy.banner !== 'null'){
      modalityHasChanged = true
    }else if(usedModality.action_name !== activeCopy.action_name && activeCopy.banner !== null && activeCopy.banner !== undefined && activeCopy.banner !== 'null'){
      modalityHasChanged = true
    }

    if(modalityHasChanged) {
      setShowAlertModal(true)
      return
    }

    if(!modalityHasChanged) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${activeModality?.id}/resource/add`)
      return
    }
  }

  const handleNameChange = (e) =>{ 
    e.preventDefault()

    const tittle_modality = e.target.name.value
    const data = { 
      tittle_modality,
      updated_by: userId
    }

    updateTitleModality(idChild, tittle_modality)
    .then (response => { 
      if(response.success){ 
        setMessage('Nombre actualizado correctamente')
        getModality(idChild)
        setEnableEditName(false)
        setTimeout(() => { 
          setMessage(null)
        }, 5000)
      }
    }).catch(error => {
      console.error(error)
    })
  }

  function getLabel(modalities, modalityId) { 
    let titleModality = modalities.find((modality) => modality.id === parseInt(modalityId))
    if(titleModality){ 
      setModalityLabel(titleModality.modality)
    }
  }

  return (
    <div>
      <Header />
      {(userType === "operative" || userType === "manager") && (
        <SprintForm
          id={id}
          idSprint={idChild}
          setCurrentPageResources={setCurrentPageResources}
          metaResources={metaResources}
          listResources={listResources}
          pagesResources={pagesResources}
          isChild={true}
          title={titleSprint}
          message={message}
          sprint={activeModality}
          changeCheckbox={changeCheckbox}
          changeInput={changeInput}
          errors={errors}
          update={update}
          editorRef={editorRef}
          initialSprint={initialModality}
          setInitialSprint={setInitialModality}
          validateChanges={validateChanges}
          enableEditName={enableEditName}
          setEnableEditName={setEnableEditName}
          handleNameCHange = {handleNameChange}
          modalityTitle={modalityLabel}
        />
      )}
      <Modal
        className={css.modal}
        show={showAlertModal}
        onClose={() => setShowAlertModal(false)}
      >
        <p className={css.modalTitle}>
          ¿Deseas guardar cambios antes de continuar?
        </p>

        <p>
          De lo contrario, tus últimos cambios no se guardaran al cambiar de vista.
        </p>

        <div className={css.buttons}>
          <Button
            onClick={() => setShowAlertModal(false)}
            className={css.none}
          >
            Cancelar
          </Button>
          <div className={css.buttons}>
            <Button
              onClick={() => {
                history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${activeModality?.id}/resource/add`)
              }}
              className={css.confirmButton}
            >
              No guardar
            </Button>
            <Button
              onClick={() => {
                setShowAlertModal(false)
                update()
              }}
              className={css.cancelButton}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

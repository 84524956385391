import React from 'react'
import { useParams } from 'react-router'

import {
  IconButton,
  withStyles,
  makeStyles,
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

// Close Icon
import CloseIcon from '@material-ui/icons/Close'

import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons'

import { faDiscord, faGithub, faBehance } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useProfile } from '../../../../context/MainContext'
import { sendEmail } from '../../../../service/module/Users'
import DialogEditProfile from './DialogEditProfile'
import DialogEditSocialMedia from './DialogEditSocialMedia'
import Button from '../partials/Button'
import css from './ProfileTab.module.css'
import Swal from 'sweetalert2'
import ornito from '../../../../assets/ornito.png'

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 30,
      paddingTop: 15,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 45,
      paddingTop: 15,
    },
  },
  title: {
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
    },
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    margin: 0,
  },
  personalData: {
    minHeight: 10,
    backgroundColor: '#f6f6f6',
    border: '1px solid #e2e2e2',
    borderRadius: 8,
    marginTop: 10,
    padding: '15px 20px',
  },
  disclaimer: {
    fontSize: theme.typography.pxToRem(16),
    color: '#54565A',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15,
    },
  },
  columnTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    fontSize: theme.typography.pxToRem(16),
    color: '#000',
    fontWeight: 700,
  },
  columnSubtitle: {
    marginTop: 5,
    fontSize: theme.typography.pxToRem(16),
    color: '#000',
    fontWeight: 500,
  },
  message: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px 0',
    },
    fontSize: theme.typography.pxToRem(16),
    color: '#222222',
    fontWeight: 700,
  },
  bioTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px 0',
    },
    fontSize: theme.typography.pxToRem(16),
    color: '#000',
    fontWeight: 700,
  },
  bioContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 30px 20px',
    },
    fontSize: theme.typography.pxToRem(16),
    color: '#000',
    fontWeight: 500,
    overflowWrap: 'break-word',
  },
  socialContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px 0',
    },
  },
  social: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
      marginBottom: 10,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    },
  },
  instagram: {
    background: 'radial-gradient(circle at 30% 107%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
  },
  facebook: {
    background: '#3b5998',
  },
  linkedIn: {
    background: '#04689B',
  },
  twitter: {
    background: '#55ADEE',
  },
  discord: {
    background: '#5864f1',
  },
  gitHub: {
    background: '#000000',
  },
  behance: {
    background: '#004bc0',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 200,
    color: '#fff',
  },
}))

const EditButton = withStyles(() => ({
  root: {
    marginTop: -4,
    padding: 2,
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton)

const SocialButton = withStyles(() => ({
  rounded: {
    borderRadius: 5,
  },
}))(Avatar)

export default function ProfileTab({ user, userId, setUpdateUser }) {
  const { setImageProfile } = useProfile()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [openSocial, setOpenSocial] = React.useState(false)
  const [uploading, setUploading] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const { id } = useParams()

  const handleOpenProfile = async () => {
    setUpdateUser(true)
    setOpen(true)
  }

  const handleOpenSocial = async () => {
    setOpenSocial(true)
  }

  function formatDate(str) {
    const date = new Date(
      str.split('-')[0],
      str.split('-')[1].replace('0', '') - 1,
      str.split('-')[2]
    )
    return date.toLocaleDateString('es-MX')
  }

  function getGender(str) {
    let genderResult
    switch (str) {
      case 'male':
        genderResult = 'Masculino'
        break
      case 'female':
        genderResult = 'Femenino'
        break
      case 'pnd':
        genderResult = 'Prefiero no decirlo'
        break
      case 'other':
        genderResult = 'Otro'
        break
      default:
        genderResult = ''
        break
    }

    return genderResult
  }

  // async function sendUnsuscribeEmail() {
  //   await sendEmail(userId)
  //     .then((response) => {
  //       if (response && response.success === true) {
  //         setModal(true)
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Ha ocurrido un error',
  //         }).then((result) => {
  //           console.error('result =>', result)
  //         })
  //       }
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Ha ocurrido un error',
  //       }).then((result) => {
  //         console.error('result =>', result)
  //       })
  //     })
  // }

  return (
    <>
      <Backdrop className={classes.backdrop} open={uploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.container}>
        {userId && `${userId}` === `${id}` && (
          <>
            <h6 className={classes.title}>
              Mi Perfil
              <EditButton
                aria-label="edit"
                disableRipple={true}
                disableFocusRipple={true}
                onClick={handleOpenProfile}
              >
                <EditOutlinedIcon />
              </EditButton>
            </h6>
            <div container item xs={12} className={classes.personalData}>
              <h6 className={classes.disclaimer}>
                Recuerda que los siguientes datos personales aquí mostrados, son solamente para ti.
                No compartimos tu información con terceros, y solamente es utilizada para tus
                certificados.
              </h6>
              <div className={css.row}>
                <div className={css.column}>
                  <h6 className={classes.columnTitle}>
                    NAO ID
                  </h6>
                  <h6 className={classes.columnSubtitle}>
                    {user && user.id ? parseInt(user.id) > 999 ? ("AN" + user.id) : ("AN0" + user.id) : null}
                  </h6>
                </div>
                <div className={css.name}>
                  <h6 className={classes.columnTitle}>Nombre</h6>
                  <h6 className={classes.columnSubtitle}>{user && user.sur_name}</h6>
                </div>
                <div className={css.column}>
                  <h6 className={classes.columnTitle}>F. Nacimiento</h6>
                  <h6 className={classes.columnSubtitle}>{user && formatDate(user.birthdate)}</h6>
                </div>
                <div className={css.column}>
                  <h6 className={classes.columnTitle}>Género</h6>
                  <h6 className={classes.columnSubtitle}>{user && getGender(user.gender)}</h6>
                </div>
                <div className={css.column}>
                  <h6 className={classes.columnTitle}>Celular*</h6>
                  <h6 className={classes.columnSubtitle}>{user && user.profile?.phone_number}</h6>
                </div>
              </div>
            </div>
            <h6 className={classes.message}>
              ¡A tu equipo le encantaría conocerte! si estas de acuerdo, comparte un poco sobre ti.{' '}
              <span
                onClick={() => {
                  handleOpenProfile()
                }}
                className={css.link}
              >
                Da click en editar perfil.
              </span>
            </h6>
          </>
        )}
        <div className={css.profileSection}>
          {user && user.profile?.bio && user.profile?.bio !== 'null' && (
            <>
              <h6 className={classes.bioTitle}>Biografía</h6>
              <h6 className={classes.bioContent}>{user && user.profile?.bio}</h6>
            </>
          )}
          <h6 className={classes.title}>
            Redes Sociales
            {userId && `${userId}` === `${id}` && (
              <EditButton
                aria-label="edit"
                disableRipple={true}
                disableFocusRipple={true}
                onClick={handleOpenSocial}
              >
                <EditOutlinedIcon />
              </EditButton>
            )}
          </h6>
        </div>
        <div className={css.socialSection}>
          {user && user.profile?.linkedin && (
            <a target="_blank" rel="noreferrer" href={user.profile?.linkedin}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.linkedIn}`}>
                <LinkedInIcon />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.twitter && (
            <a target="_blank" rel="noreferrer" href={user.profile?.twitter}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.twitter}`}>
                <TwitterIcon />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.facebook && (
            <a target="_blank" rel="noreferrer" href={user.profile?.facebook}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.facebook}`}>
                <FacebookIcon />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.instagram && (
            <a target="_blank" rel="noreferrer" href={user.profile?.instagram}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.instagram}`}>
                <InstagramIcon />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.discord && (
            <a target="_blank" rel="noreferrer" href={user.profile?.discord}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.discord}`}>
                <FontAwesomeIcon icon={faDiscord} />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.github && (
            <a target="_blank" rel="noreferrer" href={user.profile?.github}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.gitHub}`}>
                <FontAwesomeIcon icon={faGithub} />
              </SocialButton>
            </a>
          )}
          {user && user.profile?.behance && (
            <a target="_blank" rel="noreferrer" href={user.profile?.behance}>
              <SocialButton variant="rounded" className={`${classes.social} ${classes.behance}`}>
                <FontAwesomeIcon icon={faBehance} />
              </SocialButton>
            </a>
          )}
        </div>
      </div>
      
      {/*Banner "Darse de Baja" */}
      {/* <div className={css.banner}>
        <h6 className={css.titleBanner}>Borrar cuenta</h6>
        <h6 className={css.textBanner}>
          Si borras tu cuenta, tu información personal desaparecerá de los servidores de DigitalNAO,
          se perderá tu progreso y certificados. Esta acción no puede deshacerse.
        </h6>
        <Button
          isDropOut="true"
          newButton={true}
          title="Darse de baja"
          onClick={e => sendUnsuscribeEmail()}
        />
      </div> */}

      <DialogEditProfile
        setOpen={setOpen}
        open={open}
        setUploading={setUploading}
        user={user}
        userId={userId}
        setUpdateUser={setUpdateUser}
        setImage={setImageProfile}
      />
      <DialogEditSocialMedia
        setOpen={setOpenSocial}
        open={openSocial}
        setUploading={setUploading}
        user={user}
        userId={userId}
        setUpdateUser={setUpdateUser}
      />

      <Dialog
        onClose={() => {
          setModal(false)
        }}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={'md'}
        scroll={'body'}
      >
        <div className={css.modal}>
          <div alt="" className={css.close} onClick={() => {
            setModal(false)
          }}>
            <CloseIcon className={css.closeIcon}/>
          </div>
          <div className={css.image}>
            <img src={ornito} alt="" />
          </div>
          <h2 className={css.title}>Lamentamos que te vayas</h2>
          <p className={css.text}>Se ha enviado tu solicitud correctamente</p>
        </div>
      </Dialog>
    </>
  )
}

import React from 'react'

import uuid from 'react-uuid'
import moment from 'moment'

import {
  IconButton,
  withStyles,
  makeStyles,
  Avatar,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Select,
  MenuItem,
  InputBase,
  Popper,
  Fade,
} from '@material-ui/core'
import { InfoOutlined, Close as CloseIcon } from '@material-ui/icons'

import { faPhoneAlt, faCalendar, faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import defaultAvatar from '../../../../assets/default-user-profile.png'

import Input from '../partials/Input'
import Button from '../partials/Button'

import uploadFileToBlob, { isStorageConfigured } from '../../../../hooks/AzureBlobStorage'

import { updateUser } from '../../../../service/module/Users'

import css from './DialogEditProfile.module.css'
import classNames from 'classnames'
const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: '100%',
  },
  inputsContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px',
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  avatar: {
    width: 135,
    height: 135,
    position: 'relative',
  },
  avatarOverlay: {
    position: 'absolute',
    width: 143,
    height: 143,
    borderRadius: '50%',
    background: 'rgba(84, 86, 90, 0.5)',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 1,
    },
  },
  cameraIcon: {
    width: '30px !important',
    height: 30,
    color: '#fff',
  },
  cameraLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: '#fff',
    fontWeight: 600,
    margin: 0,
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: 5,
    color: '#747474',
    fontWeight: 600,
  },
  select: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  popperContainer: {
    zIndex: theme.zIndex.drawer + 100,
  },
  paper: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1.5),
    textAlign: 'center',
    backgroundColor: '#faeede',
    borderRadius: 10,
    color: '#635e5a',
  },
  mail: {
    color: '#8aa7f4',
  },
  socialTitle: {
    padding: '20px 0 10px',
  },
}))

const InformationButton = withStyles(() => ({
  root: {
    marginTop: -2,
    padding: 2,
    color: '#807f80',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton)

const ProfileButton = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton)

const BootstrapInput = withStyles(theme => ({
  input: {
    color: '#6e6e71',
    borderTopLeftRadius: '8px 20px',
    borderBottomLeftRadius: '8px 20px',
    borderTopRightRadius: '8px 20px',
    borderBottomRightRadius: '8px 20px',
    border: '2px solid #737578',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    fontWeight: 500,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderTopLeftRadius: '8px 20px',
      borderBottomLeftRadius: '8px 20px',
      borderTopRightRadius: '8px 20px',
      borderBottomRightRadius: '8px 20px',
      border: '2px solid #737578',
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(InputBase)

const styles = theme => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontFamily: 'Hind Madurai,Baskerville,Baskerville BT,serif',
    fontWeight: 500,
    lineHeight: 1.6,
    margin: 0,
  },
  dialog: {
    margin: 0,
    padding: '20px 30px 2px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    borderBottom: '1px solid #bebebe',
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <div  className={classes.dialog} {...other}>
      <h6 className={classes.title}>{children}</h6>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <div className={classes.divider}></div>
    </div>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '16px 30px',
    },
  },
  dividers: {
    borderTop: 0,
    borderBottom: 0,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '16px 30px',
  },
}))(MuiDialogActions)

export default function DialogEditProfile({
  setOpen,
  open,
  user,
  setUploading,
  setImage,
  userId,
  setUpdateUser,
}) {
  const classes = useStyles()
  const inputFileRef = React.useRef(null)

  const [imageProfile, setImageProfile] = React.useState(null)
  const [fileSelected, setFileSelected] = React.useState(null)
  const [gender, setGender] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [birthdateValue, setBirthdateValue] = React.useState(new Date())
  const [celularValue, setCelularValue] = React.useState('')
  const [aliasValue, setAliasValue] = React.useState('')
  const [bioValue, setBioValue] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState(true)
  const [linkedinValue, setLinkedinValue] = React.useState(null)
  const [facebookValue, setFacebookValue] = React.useState(null)
  const [discordValue, setDiscordValue] = React.useState(null)
  const [behanceValue, setBehanceValue] = React.useState(null)
  const [twitterValue, setTwitterValue] = React.useState(null)
  const [instagramValue, setInstagramValue] = React.useState(null)
  const [githubValue, setGithubValue] = React.useState(null)

  const [celularError, setCelularError] = React.useState(false)
  const [aliasError, setAliasError] = React.useState(false)

  const inputCelular = React.useRef()
  const inputAlias = React.useRef()

  isStorageConfigured()

  React.useEffect(() => {
    setImageProfile(
      user != null && user.profile?.image_profile != null
        ? user.profile?.image_profile
        : defaultAvatar
    )
    if (user!== undefined && user !== null) {
      setGender(user.gender ? user.gender : null)
      setImageProfile(user.profile?.image_profile)
      setBirthdateValue(moment(user.birthdate))
      setCelularValue(user.profile?.phone_number)
      setBioValue(user.profile?.bio)
      setAliasValue(user.alias)
      setIsChecked(user.allow_public_name)
      setLinkedinValue(user.profile?.linkedin || '')
      setFacebookValue(user.profile?.facebook || '')
      setDiscordValue(user.profile?.discord || '')
      setBehanceValue(user.profile?.behance || '')
      setTwitterValue(user.profile?.twitter || '')
      setInstagramValue(user.profile?.instagram || '')
      setGithubValue(user.profile?.github || '')
    }
  }, [user, open])
  const onFilechange = event => {
    const originalFile = event.target.files[0]
    const extension = originalFile.name.split('.')[1]
    const newName = uuid()
    const file = new File([originalFile], `${newName}.${extension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
    const objectUrl = URL.createObjectURL(file)
    setImageProfile(objectUrl)
    setFileSelected(file)

    return () => URL.revokeObjectURL(objectUrl)
  }

  const onBtnClick = e => {
    inputFileRef.current.click()
  }

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const openPopper = Boolean(anchorEl)
  const id = openPopper ? 'transitions-popper' : undefined

  const handleChange = event => {
    setGender(event.target.value)
  }

  const handleSave = async e => {
    e.preventDefault()

    if (celularError || aliasError) {
      return
    } else {
      if (inputCelular.current.value.length < 9 || inputAlias.current.value.length === 0) {
        if (inputCelular.current.value.length < 9) setCelularError(true)
        if (inputAlias.current.value.length === 0) setAliasError(true)
        return
      }
    }

    setUploading(true)

    var urlUploadedFile = user.profile?.image_profile
    if (fileSelected != null) {
      urlUploadedFile = await uploadFileToBlob(fileSelected)
    }

    let data = {
      email: user.email,
      user_type: user.user_type,
      names: user.names ? user.names : ' ',
      last_name: user.last_name ? user.last_name : ' ',
      sur_name: user.sur_name ? user.sur_name : '',
      gender: gender ? gender : 'other',
      phone_number: celularValue,
      bio: bioValue ? bioValue : '',
      birthdate: birthdateValue.format('yyyy-MM-DD'),
      allow_public_name: isChecked ? 1 : 0,
      alias: aliasValue,
      image_profile: urlUploadedFile,
      linkedin: linkedinValue,
      facebook: facebookValue,
      discord: discordValue,
      behance: behanceValue,
      twitter: twitterValue,
      instagram: instagramValue,
      github: githubValue,
    }

    await updateUser(userId, data)
      .then(() => {
        setFileSelected(null)
        setUploading(false)
        setOpen(false)
        setAnchorEl(false)
        setUpdateUser(true)
        setImage(urlUploadedFile)
        setImageProfile(urlUploadedFile)
      })
      .catch(() => {
        setUploading(false)
      })
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(false)
  }

  const isDisabled = () => {
    if (user && user.profile) {
      if (
        (gender === user.gender || gender === '') &&
        (aliasValue === '' || aliasValue === user.alias) &&
        (bioValue === '' || bioValue === user.profile?.bio) &&
        (birthdateValue === '' ||
          moment(birthdateValue)._d.toDateString() === moment(user.birthdate)._d.toDateString()) &&
        (celularValue === '' || celularValue === user.profile?.phone_number) &&
        (imageProfile === '' || imageProfile === user.profile?.image_profile) &&
        isChecked === user.allow_public_name
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={'md'}
      scroll={'body'}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Editar Perfil
      </DialogTitle>
      <DialogContent dividers>
        <form className={css.form}>
          <div className={css.row}>
            <div className={classes.avatarContainer}>
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                ref={inputFileRef}
                onChange={onFilechange}
                accept=".jpg, .gif, .png"
              />
              <ProfileButton onClick={e => onBtnClick(e)}>
                <Avatar alt="" src={imageProfile} className={classes.avatar} />
                <div className={classes.avatarOverlay}>
                  <FontAwesomeIcon icon={faCamera} className={classes.cameraIcon} />
                  <span className={classes.cameraLabel}>Cambiar foto</span>
                </div>
              </ProfileButton>
            </div>
            <div className={css.column}>
              <label className={classes.label}>
                Nombre:
                <InformationButton
                  aria-label="edit"
                  disableRipple={true}
                  disableFocusRipple={true}
                  onClick={handleClick}
                >
                  <InfoOutlined style={{ width: 17, height: 17 }} />
                </InformationButton>
              </label>
              <Popper
                id={id}
                open={openPopper}
                anchorEl={anchorEl}
                className={classes.popperContainer}
                transition
                placement={'right'}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <div className={classNames(classes.paper, css.popup)}>
                      Si necesitas cambiar tu <br />
                      nombre, envía un correo a <br />{' '}
                      <span className={classes.mail}>soporte@nao.com</span>
                    </div>
                  </Fade>
                )}
              </Popper>
              <Input type="text" title="Nombre" value={user && user.sur_name} readonly={true} />
              <div className={css.checkboxContainer}>
                <input
                  name="allow_public_name"
                  className={css.checkbox}
                  id="allow_public_name_id"
                  type="checkbox"
                  onChange={e => {
                    setIsChecked(!isChecked)
                  }}
                  value={isChecked}
                  checked={isChecked}
                />
                <label className={css.checkboxLabel} htmlFor="allow_public_name_id">
                  Mostrar nombre en mi perfil público
                </label>
              </div>
              <label className={classes.label} style={{marginTop: "12px"}}>
                Correo:
              </label>
              <Input type="text" title="Correo" value={user && user.email} readonly={true} />
            </div>
          </div>
          <div className={css.row}>
            <div className={css.column}>
              <label className={classes.label}>Fecha de Nacimiento:</label>
              <Input
                valueDate={birthdateValue}
                type="date"
                icon={<FontAwesomeIcon icon={faCalendar} />}
                isDate={true}
                onChangeDate={e => {
                  setBirthdateValue(e)
                }}
                title="Fecha de Nacimiento"
              />
            </div>
            <div className={css.column}>
              <label className={classes.label}>Celular: *</label>
              <Input
                value={celularValue}
                type="text"
                pattern="\d*"
                ref={inputCelular}
                error={celularValue && celularValue.length > 0 ? false : true}
                onChange={e =>
                    setCelularValue(v => {
                      setCelularError(e.target.value.length > 0 ? false : true)
                      return e.target.value.length > 10 ? v : e.target.value
                    })} 
                
                icon={<FontAwesomeIcon icon={faPhoneAlt} />}
                title="Celular"
                errorMessage="Este campo es obligatorio"
                isRequerid={true}
              />
            </div>
          </div>
          <div className={css.row}>
            <div className={css.column}>
              <label className={classes.label}>Género:</label>
              <Select
                className={classes.select}
                value={gender}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                <MenuItem value={'female'}>Femenino</MenuItem>
                <MenuItem value={'male'}>Masculino</MenuItem>
                <MenuItem value={'pnd'}>Prefiero no decirlo</MenuItem>
                <MenuItem value={'other'}>Otro</MenuItem>
              </Select>
            </div>
            <div className={css.column}>
              <label className={classes.label}>Alias: *</label>
              <Input
                type="text"
                title="Alias"
                value={aliasValue}
                ref={inputAlias}
                error={aliasValue && aliasValue.length > 0 ? false : true}              
                onChange={e =>
                  setAliasValue(() => {
                    setAliasError(e.target.value.length === 0 ? true : false)
                    return e.target.value
                  })
                }
                errorMessage="Proporciona un alias en lugar de tu nombre"
                isRequerid={true}
              />
            </div>
          </div>
          <div className={css.description}>
            <label className={classes.label}>Biografía:</label>
            <Input
              onChange={e => setBioValue(v => (e.target.value.length > 250 ? v : e.target.value))}
              type="textarea"
              value={bioValue}
              title="Biografía"
            />
            <span className={classes.label}>*Campos obligatorios</span>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button newButton={true} light="true" title="Cancelar" onClick={handleClose} />
        <Button
          newButton={true}
          type="button"
          title="Guardar"
          disabled={isDisabled()}
          onClick={e => handleSave(e)}
        />
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Header from '../../components/modules/dashboard/partials/Header'
import GroupForm from '../../components/modules/dashboard/groups/GroupForm'
import { getGroupBy, updateGroup, getUsersByGroup, deleteUser} from '../../service/module/Groups'
import { getAllRoles } from '../../service/module/Roles'
import { useProfile } from '../../context/MainContext'
import Swal from 'sweetalert2'
import css from './AddGroup.module.css'
import { getAllModalities } from '../../service/module/Modalities'

export default function EditGroup() {
  const { id } = useParams()
  const [group, setGroup] = React.useState({})
  const [errors, setErrors] = React.useState()
  const [roles, setRoles] = React.useState()
  const [message, setMessage] = React.useState()
  const { userType, userId } = useProfile()
  const history = useHistory()
  const [users, setUsers] = React.useState([])
  const [groupTemp, setGroupTemp] = React.useState({})
  const [changed, setChanged] = React.useState(true) 
  const [modalities, setModalities] = React.useState()


  async function getRoles() {
    await getAllRoles()
      .then(response => {
        setRoles(response.data)
      })
      .catch(err => {
        console.error(err.message)
      })
  }
  
  async function getGroup(id) {
    await getGroupBy(id)
      .then(response => {
        const item = response.data[0]
        setGroup(item)
        setGroupTemp(item)
      })
      .catch(() => {})
  }

  async function getUsers(id){ 
    await getUsersByGroup(id)
    .then(response => {
      setUsers(response.data)
    })
    .catch((error) => console.error(error))
  }

  async function getModalities(){ 
    await getAllModalities()
    .then(response => { 
      setModalities(response.data)
    })
    .catch(error =>  {
      console.error(error.message)
    })
  }

  React.useEffect(() => {
    getGroup(id)
    getRoles()
    getModalities()
    id !== undefined && getUsers(id)
  }, [id, userId])

  React.useEffect(() => { 
    isEqual(group, groupTemp)
  },[group, groupTemp])

  function isEqual(group, groupTemp){ 
      if(group.group_name !== groupTemp.group_name){
        setChanged(false)
      }else if(group.group_description !== groupTemp.group_description){
        setChanged(false)
      }else if(group.role_id !== groupTemp.role_id){
        setChanged(false)
      }else if(group.group_status !== groupTemp.group_status){
        setChanged(false)
      }else if(group.assessment !== groupTemp.assessment){
        setChanged(false)
      }else{ 
        setChanged(true)
      }
  }


  function changeInput(e) {
    if(e.target.name === 'group_status') {
      // If the input is a radio button, then we need to check if the input is checked and if its the true radio button and if it is, then we set the value to 1, otherwise we set it to 0
      setGroup({
        ...group,
        [e.target.name]: e.target.id === "input-group-status-true" && e.target.checked === true ? 1 : 0,
        updated_by: userId,
      })
    } else {
      setGroup({
        ...group,
        [e.target.name]: e.target.value,
        updated_by: userId,
      })
    }
    isEqual(group, groupTemp)
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function update() {
    await updateGroup(id, group)
      .then(response => {
        if (response?.success === true) {
          swalCustom.fire({
            title: '¡Grupo actualizado exitosamente!',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/groups`)
          })
        } else {
          swalCustom.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/groups`)
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/groups`)
        })
      })
  }

 

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <GroupForm
          title="Editar Grupo"
          message={message}
          group={group}
          roles={roles}
          changeInput={changeInput}
          errors={errors}
          update={update}
          users= {users}
          changed={changed}
          setUsers={setUsers}
          modalities={modalities}
        />
      )}
    </div>
  )
}

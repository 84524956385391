// DEV
import { useState } from 'react';
// const BOT_BACKEND_URL = "http://localhost:5000"
const BOT_BACKEND_URL = "https://sofia.naoincubacion.com.mx"


const useSofia = () => {
  const [botIsWriting, setBotIsWriting] = useState(false);

    // * Try to get a response from LLM API. 
    // * If not possible, returns null botResponse and the presented error
    const getBotResponse = async (question) => {
        // Prepare prompt
        const preparedPrompt = "Eres una experta en programación y solo responde acerca de este tema. Tu nombre es SofIA y"
            // .concat(" ", "eres tambien un fantasma lo que te hace que muy seguido alargas las vocales de las palabras")
            // .concat(" ", "(por ejemplo en palabras alargarias las vocales como 'aaa', 'eee', 'iii', 'ooo', 'uuu'),")
            .concat(" ", "Tu objetivo es guiarlos hacia posibles soluciones sin proporcionar respuestas directas.")
            // .concat(" ", "Si vas a retornar un ejemplo de código, debrás retornarlo siempre y solamente al final del mensaje.")
            .concat(" ", "Tu respuesta debe estar en línea con el siguiente mensaje y las limitaciones con las que cuentas:")
            .concat(" ", question)
        // Stringify prompt data 
        const data = JSON.stringify({ prompt: preparedPrompt })
        // Fetch options
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: data
        }
        // Start Loading Animation
        setBotIsWriting(true)
        let response = null
        try {
            response = await fetch(`${BOT_BACKEND_URL}/generate`, options)
        // Error
        } catch (error) {
            console.error('Error fetching with useSofia hook:', error) // TODO: Log into some DB or Logging system
            return [null, error]
        } finally {
            // End Loading Animation
            setBotIsWriting(false)
        }
        // Success
        const botResponse = await response.json()
        return [botResponse.response, null]
    }

  return {
    botIsWriting,
    getBotResponse
  }
};

export default useSofia;
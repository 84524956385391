import React from 'react'

import Header from '../../components/modules/dashboard/partials/Header'
import GroupForm from '../../components/modules/dashboard/groups/GroupForm'

import { getAllRoles } from '../../service/module/Roles'
import { storeGroup } from '../../service/module/Groups'
import { useProfile } from '../../context/MainContext'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import css from './AddGroup.module.css'
import { getAllModalities } from '../../service/module/Modalities'

export default function AddGroup() {
  const [errors, setErrors] = React.useState()
  const [group, setGroup] = React.useState({})
  const [roles, setRoles] = React.useState()
  const { userType, userId } = useProfile()
  const history = useHistory()
  const [modalities, setModalities] = React.useState()
  const [changed, setChanged] = React.useState(true) 

  async function getRoles() {
    await getAllRoles()
      .then(response => {
        setRoles(response.data)
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  async function getModalities(){ 
    await getAllModalities()
    .then(response =>{ 
      setModalities(response.data)
    })
    .catch(error => {
      console.error(error.message)
    })
  }

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    getRoles()
    getModalities()
  }, [])

  React.useEffect(() => { 
    validate(group)
  }, [group])

  function changeInput(e) {
    if (e.target.name === 'group_status') {
      // If the input is a radio button, then we need to check if the input is checked and if its the true radio button and if it is, then we set the value to 1, otherwise we set it to 0
      setGroup({
        ...group,
        [e.target.name]: e.target.id === "input-group-status-true" && e.target.checked === true ? 1 : 0,
        created_by: userId,
      })

    } else {
      setGroup({
        ...group,
        [e.target.name]: e.target.value,
        created_by: userId,
      })

    }
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    
    await storeGroup(group)
      .then(response => {
        if (response?.success === true) {
          swalCustom.fire({
            title: '¡Grupo creado exitosamente!',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/groups`)
          }).then(_result => {
            history.push(`/dashboard/groups`)
          })
        } else {
          swalCustom.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/groups`)
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/groups`)
        })
      })
  }


function validate(group) { 
  if(group.group_name && group.group_name !== null
     && group.group_name !== "" && group.modality_id 
     && group.modality_id !== null && group.modality_id !== "" && group.role_id
     && group.role_id !== null && group.role_id !== "" && group.assessment 
     && group.assessment !== null && group.assessment !== "" ){
      if(group.group_status && (group?.group_status === 1 || group?.group_status === 0))
       setChanged(false)
     }else{
      setChanged(true)
     }
}


  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <GroupForm
          roles={roles}
          register={register}
          changeInput={changeInput}
          errors={errors}
          group={group}
          title="Agregar Grupo"
          modalities={modalities}
          changed={changed}
        />
      )}
    </div>
  )
}

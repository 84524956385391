import React, { useRef } from 'react'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { Alert } from 'reactstrap'
import Button from '../partials/Button'
import { getSeniority } from '../../../../service/module/Users'
import css from './RolesForm.module.css'
import { getUpdateChallenges, getChallengesByRole, deleteChallengeRole } from '../../../../service/module/Roles'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import checkIcon from '../../../../assets/Check.png'
import { useProfile } from '../../../../context/MainContext'

export default function RolesForm({
  message,
  setMessage,
  title,
  changeInput,
  register,
  errors,
  role,
  update,
  id,
  changed,
  deleteChallenge,
  modalities,
  turnOnOffModality,
  modalityUpdated,
  setModalityUpdated,
}) {
  const history = useHistory()
  const dragItem = useRef()
  const dragOverItem = useRef()
  const [roles, setRoles] = React.useState()
  const [listChallenges, setListChallenges] = React.useState([])
  const { userId } = useProfile()
  const [isChallengeDeleted, setIsChallengeDeleted] = React.useState()

  const dragStart = (e, position) => {
    dragItem.current = position
  }

  const dragEnter = (e, position) => {
    dragOverItem.current = position
  }

  localStorage.removeItem('challenges_select')

  React.useEffect(() => {
    async function getRoles() {
      await getSeniority()
        .then(response => {
          setRoles(response.data)
        })
        .catch(() => {})
    }

    getRoles()
  }, [])

  const drop = e => {
    const copyListItems = [...listChallenges]
    const dragItemContent = copyListItems[dragItem.current]
    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
    setListChallenges(copyListItems)

    let challengeData = []
    copyListItems.map((challenge, index) => {
      let challengeObject = {
        challenge_id: challenge[0].id,
        sequence: index + 1,
        id: challenge[0].challenge_role_id,
      }
      challengeData.push(challengeObject)
    })

    let da = {
      role_id: role.id,
      challenges: challengeData,
    }

    getUpdateChallenges(da).then(response => {
      if (response.success) {
        setMessage("¡El orden de los retos ha sido actualizado correctamente!")

          setTimeout(() => {
            setMessage(null)
          }, 5000)
      }
      if (!response.success) {
        setMessage({
          message: "Ha ocurrido un error al actualizar el orden de los retos"
        })
        setTimeout(() => {
          setMessage(null)
        }, 5000)
      }
    })
  }

  React.useEffect(() => {
    async function getAllChallenges(id) {
      await getChallengesByRole(id)
        .then(response => {
          let challenges = response.response || []

          var orderedChallenges = []
          // order challenges as current listChallenges
          if (listChallenges?.length > 0) {
            listChallenges.map(challenge => {
              let challengeObject = challenges.find(challengeTemp => {
                return challengeTemp[0].id === challenge[0].id
              })
              if (challengeObject) {
                orderedChallenges.push(challengeObject)
              }
            })
          } else {
            orderedChallenges = challenges
          }
          
          setModalityUpdated(false)
          setListChallenges(orderedChallenges)
        })
        .catch(error => {
          console.error(error)
        })
    }

    if(id && (isChallengeDeleted || modalityUpdated || listChallenges.length === 0)) {
      getAllChallenges(id)
    }
  }, [id, isChallengeDeleted, modalityUpdated])

  function addChallenges(e) {
    e.preventDefault()
    history.push(`/dashboard/roles/${role.id}/edit/addchallenges`)
  }

  async function deleteChallenge(challengeId){ 
    await deleteChallengeRole(challengeId, {"created_by": userId })
    .then(response => { 
      setMessage("¡El reto ha sido borrado correctamente!")
          setModalityUpdated(true)

          setTimeout(() => {
            setMessage(null)
            setModalityUpdated(false)
          }, 5000)
      setIsChallengeDeleted(!isChallengeDeleted)
    }).catch(error => { 
      setMessage({
        message: "Ha ocurrido un error al borrar el reto"
      })
      setTimeout(() => {
        setMessage(null)
      }, 5000)
    })

  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id){    
    swalCustom.fire({
      title: '¿Estás seguro que deseas quitar este reto?',
            iconHtml: '<div className="' + css.warningIcon +'"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Quitar reto',
    }).then(_result => {
      if (_result.isDenied) {
        deleteChallenge(id)
      }
    })
  }

  return (
    <>
      <div className={css.root}>
        {message && typeof message === 'object' ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt=""/><strong>{message.message}</strong>
          </Alert>
        ) : message ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt=""/><strong>{message}</strong>
          </Alert>
        ) : null}
        <div className={classNames(css.tableContainer, css.darkTableContainer)}>
          <div className={css.tableHeader}>
            <h3 className={css.title}>{title}</h3>
          </div>
          <form className={css.form}>
            <div className={css.inputs}>
              <div className={css.inputHolder}>
                <label className="form-control-label" htmlFor="input-name">
                  Nombre
                </label>
                <input
                  invalid={errors && errors.name ? true : false}
                  onChange={changeInput}
                  name="name"
                  id="input-name"
                  defaultValue={role && role.name}
                  className={css.input}
                  type="text"
                />
                {errors && errors.name && <div className={css.feedback}>{errors.name}</div>}
              </div>
              <div className={css.inputHolder}>
                <label className="form-control-label" htmlFor="input-seniority">
                  Seniority
                </label>
                <select
                  invalid={errors && errors.seniority ? true : false}
                  onChange={changeInput}
                  name="seniority"
                  id="input-seniority"
                  defaultValue={role && role.seniority}
                  className={css.input}
                  type="select"
                  disabled={update && update !== undefined ? true : false}
                >
                  {roles &&
                    roles.map(value => (
                      <option selected={value?.seniority === role?.seniority} key={value.id}>
                        {value?.seniority}
                      </option>
                    ))}
                </select>
                {errors && errors.seniority && (
                  <div className={css.feedback}>{errors.seniority}</div>
                )}
              </div>
            </div>
          </form>
          <div
            className={classNames(css.tableContainer, {
              [css.hidden]: !!register,
            })}
            style={{ margin: '0 39px 48px' }}
          >
            <div className={css.tableHeader}>
              <h3 className={css.title}>
                Retos asignados
                <span className={css.small}>Edita los retos desde la sección de retos</span>
              </h3>
              <Button
                className={css.addButton}
                onClick={addChallenges}
                disabled={listChallenges?.length >= 10 ? true : false}
              >
                Agregar
              </Button>
            </div>
            <div className={css.tableHolder}>
              <div className={css.table}>
                <div className={css.thead}>
                  <div className={css.tr}>
                    <div scope="col" className={css.th}>
                      Mover
                    </div>
                    <div scope="col" className={classNames(css.th, css.titleTh)}>
                      Nombre
                    </div>
                    {modalities?.map((modality, index) => {
                      return (
                        <div scope="col" className={classNames(css.th, css.tdModalities)} key={index}>
                          {modality.modality}
                        </div>
                      )
                    })}
                    <div scope="col" className={css.th}>
                      Orden
                    </div>
                    <div scope="col" className={css.th}>
                      Acciones
                    </div>
                  </div>
                </div>
                <div className={css.tbody}>
                  {listChallenges &&
                    listChallenges.map((item, key) => {
                      return (
                        <div
                          key={key}
                          className={css.tr}
                          draggable
                          onDragStart={e => dragStart(e, key)}
                          onDragEnter={e => dragEnter(e, key)}
                          onDragEnd={drop}
                        >
                          <div className={classNames(css.td)}>
                            <Button isicon={false} className={css.order}>
                              <span>
                                <FontAwesomeIcon icon={faBars} className={css.order} />
                              </span>
                            </Button>
                          </div>
                          <div className={css.titleCol}>
                            <span className={css.name}>{item[0].name}</span>
                            <p className={css.description}>{item[0].description}</p>
                          </div>
                            {modalities?.map((modality, index) => {
                              const hasItem = item[0].modalities?.find((temp) => {
                                return temp.modality_id === modality.id
                              })

                              return (
                                <div className={classNames(css.td, css.tdModalities)}>
                                  {hasItem ? (
                                    <input
                                    type="checkbox"
                                    id={modality.id}
                                    name={modality.id}
                                    checked={hasItem ? hasItem.visible === 1 ? true : false : true}
                                    onChange={(e) => turnOnOffModality(e, item[0].id)}
                                  />
                                  ) : "-"}
                                </div>
                              )
                            })}
                          <div className={css.thCenter}>{key + 1}</div>
                          <div
                            className={classNames(css.td, css.actionButtons)}
                            style={{ textAlign: 'center' }}
                          >
                            <Button
                              isicon={true}
                              className={css.delete}
                              type="button"
                              title="Quitar"
                              onClick={() => toggleModal(item[0].challenge_role_id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faXmark} />
                              </span>
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className={css.tableFooter}>
                {!listChallenges ||
                  (listChallenges?.length === 0 && (
                    <div className={css.noData}>
                      <p>Sin retos asignados</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={css.tableFooter}>
            <div className="pagination justify-content-end">
              <Button
                className={css.delete}
                type="button"
                onClick={() => {
                  history.push('/dashboard/roles')
                }}
              >
                Cancelar
              </Button>
              {register && (
                <Button type="button" onClick={register} className={css.registerButton}>
                  Registrar
                </Button>
              )}
              {update && (
                <Button
                  type="button"
                  onClick={update}
                  className={css.registerButton}
                  disabled={changed}
                >
                  Actualizar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

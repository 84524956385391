import React from 'react'
import { useHistory } from 'react-router'

import uuid from 'react-uuid'

import {
  makeStyles,
  Avatar,
  Drawer,
  withStyles,
  LinearProgress,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'

import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../partials/Button'
import defaultAvatar from '../../../../assets/default-user-profile.png'
import { logoutApi } from '../../../../service/module/Auth'
import { removeToken } from '../../../../service/HelperServices'

import { updateImageProfile } from '../../../../service/module/Users'

import uploadFileToBlob, { isStorageConfigured } from '../../../../hooks/AzureBlobStorage'

import css from './AsideDrawer.module.css'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '25%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '25%',
    backgroundColor: '#F6F6F6',
    height: 'calc(100% - 85px)',
    top: 85,
  },
  drawerContainer: {
    overflow: 'auto',
    padding: '85px 0 0',
  },
  avatar: {
    width: 180,
    height: 180,
    position: 'relative',
  },
  avatarOverlay: {
    position: 'absolute',
    width: 180,
    height: 180,
    borderRadius: '50%',
    background: 'rgba(84, 86, 90, 0.5)',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 1,
    },
  },
  name: {
    fontFamily: 'Baskerville BT',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 0,
    color: '#000',
  },
  userName: {
    fontSize: theme.typography.pxToRem(18),
    textAlign: 'center',
    color: '#000',
  },
  userType: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    textAlign: 'center',
    marginTop: 45,
    textTransform: 'uppercase',
    color: '#000',
  },
  progressText: {
    marginTop: 20,
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    color: '#54565A',
  },
  blueText: {
    color: '#004DFF',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
  },
  cameraIcon: {
    width: '30px !important',
    height: 30,
    color: '#fff',
  },
  cameraLabel: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 300,
    color: '#fff',
  },
  role: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  roleBlue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#004DFF',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
}))

const ProfileButton = withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton)

export default function AsideDrawer({
  user,
  id,
  userId,
  logout,
  setImage,
  role,
  modalities,
  modality
}) {
  const classes = useStyles()
  const history = useHistory()
  const inputFileRef = React.useRef(null)
  const [imageProfile, setImageProfile] = React.useState(null)
  const [names, setNames] = React.useState(null)
  const [alias, setAlias] = React.useState(null)
  const [uploading, setUploading] = React.useState(false)

  isStorageConfigured()

  const onFilechange = async event => {
    setUploading(true)
    const originalFile = event.target.files[0]
    const extension = originalFile.name.split('.')[1]
    const newName = uuid()
    const file = new File([originalFile], `${newName}.${extension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
    const objectUrl = URL.createObjectURL(file)

    const urlUploadedFile = await uploadFileToBlob(file)

    let data = {
      image_profile: urlUploadedFile,
    }

    await updateImageProfile(userId, data)
      .then(() => {
        setImageProfile(objectUrl)
        setImage(objectUrl)
        setUploading(false)
      })
      .catch(err => {
        console.error(err.message)
      })

    return () => URL.revokeObjectURL(objectUrl)
  }

  React.useEffect(() => {
    setImageProfile(
      user != null && user.profile?.image_profile != null
        ? user.profile?.image_profile
        : defaultAvatar
    )
    setNames(
      user && (user?.allow_public_name === 1 || id.toString() === userId.toString())
        ? user.names
        : ''
    )
    setAlias(user && user?.alias !== null ? user.alias : '')

  }, [user])

  const onBtnClick = e => {
    inputFileRef.current.click()
  }

  async function userLogout() {
    logout()
    await logoutApi()
      .then(response => {
        if (response.success) {
          removeToken()
          history.push('/')
        }
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={uploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={css.container}>
          <div className={classes.avatarContainer}>
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept="image/*"
              ref={inputFileRef}
              onChange={onFilechange}
            />
            <ProfileButton
              onClick={e => {
                if (`${id}` === `${userId}`) {
                  onBtnClick(e)
                }
              }}
            >
              <Avatar alt="" src={imageProfile} className={classes.avatar} />
              {`${id}` === `${userId}` && (
                <div className={classes.avatarOverlay}>
                  <FontAwesomeIcon icon={faCamera} className={classes.cameraIcon} />
                  <span className={classes.cameraLabel} gutterBottom>
                    Cambiar foto
                  </span>
                </div>
              )}
            </ProfileButton>
          </div>
          <h5 className={classes.name}>{names ? names : ''}</h5>
          <p className={classes.userName}>{alias ? '@' + alias : ''}</p>
          {role && (
              <h5 className={classes.userType}>
                {user && role ? (
                  user.user_type === 'learner' ? (
                    <p className={classes.role}>
                      Cursando
                      {role.split(' ').length > 1 ? <br /> : ' '}
                      <span className={classes.roleBlue}>{role}</span>
                    </p>
                  ) : (
                    'Mentor'
                  )
                ) : (
                  ''
                )}
                {modality && <span className={css.subtext}>Modalidad {modality.modality}</span>}
              </h5>
          )}
          {`${id}` === `${userId}` && (
            <Button
              className={css.logout}
              newButton={true}
              light="true"
              title="Cerrar Sesión"
              onClick={e => userLogout()}
            />
          )}
        </div>
      </Drawer>
    </>
  )
}

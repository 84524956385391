import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { logoutApi } from '../../../../service/module/Auth'
import { removeToken } from '../../../../service/HelperServices'
import { useProfile } from '../../../../context/MainContext'

import css from './NavBar.module.css'

export default function NavBar({ brandText, location }) {
  const history = useHistory()
  const { logout, user } = useProfile()
  const [openDropdown, setOpenDropdown] = useState(false)

  async function userLogout() {
    logout()
    await logoutApi()
      .then(response => {
        if (response.success) {
          removeToken()
          history.push('/')
        }
      })
      .catch((err) => { console.error(err.message) })
  }

  function isNan(v) {
    return /\d/.test(v);
  }

  return (
    <>
      <div className={css.root}>
        <div className={css.container}>
          <div>
            {brandText && brandText.length > 0 && brandText.map((item, index) => {
              return (
                <>
                  <button
                    className={css.backLink}
                    disabled={index >= brandText.length - 1}
                    onClick={() => {
                      if(index < brandText.length - 1) {
                        const paths = brandText[index + 1].last.split('/')
                        const pathnameSplit = location.pathname.split('/')
                        // if paths[n] is a number, then change it for the same index in pathnameSplit
                        for (let i = 0; i < paths.length; i++) {
                          if (isNan(paths[i])) {
                            paths[i] = pathnameSplit[i]
                          }
                        }
                        history.push(paths.join('/'))
                      }
                    }}
                  >
                    {item.label}
                  </button>
                  {index < brandText.length - 1 && <span className={css.separator}> > </span>}
                </>
              )
            })}
          </div>
          <ul className={css.dropdown}>
            <li key="open-dropdown" className={css.dropdownContainer}>
              <div className={css.dropToggle} onClick={() => {
                setOpenDropdown(!openDropdown);
              }}>
                  <span
                    className={css.avatar}
                    style={{
                      backgroundColor: '#f1f3f5',
                      border: '3px solid #d6dbe0',
                    }}
                  >
                    <img
                      alt="..."
                      className={css.avatarImg}
                      src={
                        user != null && user.picture != null
                          ? user.picture
                          : require('../../../../assets/default-user-profile.png').default
                      }
                    />
                  </span>
                  <span className={css.text}>{user && user.name}</span>
              </div>
              <div className={classNames(css.dropdownMenu, {
                [css.show]: openDropdown
              })}>
                <div className={css.dropdownHeader}>
                  <h6 className={css.dropTitle}>¡Bienvenido!</h6>
                </div>
                {/*
               <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Mi Perfil</span>
                </DropdownItem>
                <DropdownItem divider />
                */}
                <button href="#logout" className={css.dropdownItem} onClick={() => userLogout()}>
                <i className={classNames(css.icon, "ni ni-user-run")} />
                <span>Logout</span>
              </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

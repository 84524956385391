import React, { useEffect, useState } from 'react'
import css from './ChallengesModal.module.css'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Pagination, PaginationItem, PaginationLink, Alert } from 'reactstrap'
import Button from '../../../modules/dashboard/partials/Button'
import { getAllChallenges, getFindChallenge } from '../../../../service/module/Challenges'
import { faAngleLeft, faAngleRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'

import { getChallengesByRole } from '../../../../service/module/Roles'

export default function ChallengesModal({
  message,
  title,
  role,
  update,
  selectedOption,
  register,
  changeInput,
  challengesFound,
  word,
  changed, 
  setChanged,
  registerChanged
}) {

  const [colorMessage, setColorMessage] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [meta, setMeta] = React.useState()
  const [list, setList] = React.useState()
  const [pages, setPages] = React.useState()
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const history = useHistory()
  const location = useLocation()
  const [challengesRole, setChallengesRole] = React.useState()
  const [challengesTemp, setChallengesTemp]= React.useState()

  let challenges =
    localStorage.getItem('challenges_select') === null
      ? []
      : JSON.parse(localStorage.getItem('challenges_select'))

  const [checkedState, setCheckedState] = React.useState(
    new Array(list && list !== null ? list.length : 10).fill(false)
  )
  const [pastCheckedState, setPastCheckedState] = React.useState(
    new Array(list && list !== null ? list.length : 10).fill(false)
  )

  React.useEffect(() => {
    async function getAllChallenges(role) {
      await getChallengesByRole(role)
        .then(response => {
          setChallengesRole(response.response)
          setChallengesTemp(response.response)
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (role) {
      getAllChallenges(role)
    }
  }, [role])

  React.useEffect(() => {
    if (location.message) {
      setColorMessage('success')
    }
  }, [location])

  React.useEffect(() => {
    async function getChallenges(current) {
      await getAllChallenges(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => {})
    }

    async function getFindChallenges(id, page) {
      await getFindChallenge(id, page)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }

    challenges =
      localStorage.getItem('challenges_select') === null
        ? []
        : JSON.parse(localStorage.getItem('challenges_select'))
    selectedOption(challenges)
    setCheckedState(new Array(list && list !== null ? list.length : 10).fill(false))
    setPastCheckedState(new Array(list && list !== null ? list.length : 10).fill(false))

    if (!word || word === '') {
      getChallenges(currentPage)
    } else {
      getFindChallenges(word, currentPage)
    }
  }, [currentPage, word])

  React.useEffect(() => {
    const array = new Array(list && list !== null ? list.length : 10).fill(false)
    const pastArray = new Array(list && list !== null ? list.length : 10).fill(false)

    list &&
      list.map((item, index) => {
        challenges &&
          challenges.map(challenge => {
            if (item.id === challenge.challenge_id) {
              array[index] = 'true'
              setCheckedState(array)
            }
          })
        challengesRole &&
          challengesRole.map(challenge => {
            if (item.id === challenge[0].id) {
              array[index] = 'true'
              pastArray[index] = 'true'
              setCheckedState(array)
              setPastCheckedState(pastArray)
            }
          })
      })
  }, [list])

  function search() {
    async function getFindChallenges(word) {
      await getFindChallenge(word)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }
    getFindChallenges(word)
  }

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  function toggleModal() {
    setIsOpenModal(!isOpenModal)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage('')
      setCurrentPage(meta.next_page_url)
    }
  }

  const handleOnChange = position => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item
    })

    setCheckedState(updatedCheckedState)
    challenges &&
      list &&
      list.map((item, index) => {
        if (position === index) {
          if (updatedCheckedState[index] === true) {
            //se agrega a la lista
            if ((challenges?.length + challengesRole?.length) < 10) {
              challenges.push({ challenge_id: item.id })
              localStorage.setItem('challenges_select', JSON.stringify(challenges))
              selectedOption(challenges)
              //isEqual(challenges, challengesTemp)
            } else {
              Swal.fire({
                title: 'Aviso',
                text: 'Sólo puedes seleccionar 10 retos',
                icon: 'info',
                confirmButtonText: 'Regresar',
              })
              updatedCheckedState[index] = false
              setCheckedState(updatedCheckedState)
            }
          } else {
            //se elimina de la lista
            const filteredChallenges = challenges.filter(
              challenge => challenge.challenge_id !== item.id
            )
            selectedOption(filteredChallenges)
            challenges = filteredChallenges
            localStorage.setItem('challenges_select', JSON.stringify(filteredChallenges))
            //isEqual(challenges, challengesTemp)
          }
        }
      })
  }

  React.useEffect(() =>{ 
    if(!registerChanged){ 
      isEqual(challenges, challengesTemp)
    }
  },[challenges, challengesTemp])

  function isEqual(challenges, challengesTemp){ 
    if(challenges.length > 0 ){ 
      setChanged(false)
    }else{
      setChanged(true)
    }
  }

  return (
    <>
      <div>
        <div className={classNames(css.root)}>
          {message && colorMessage && (
            <Alert color={colorMessage}>
              <strong>¡{colorMessage.charAt(0).toUpperCase() + colorMessage.slice(1)}!</strong>{' '}
              {message}
            </Alert>
          )}
          <div className={css.tableContainer}>
            <div className={css.tableHeader}>
              <h3 className={css.title}>{title}</h3>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={classNames(css.inputGroup, css.input)}>
                  <div className={css.iconHolder}>
                    <div className={css.icon}>
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                  </div>
                  <input
                    name="search"
                    id="input-search"
                    className={css.input}
                    type="text"
                    placeholder="Buscar"
                    onChange={changeInput}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        search()
                      }
                    }}
                  />
                </div>
                <Button type="button" onClick={search} className={css.searchButton}>
                  Buscar
                </Button>
              </div>
            </div>
            <div className={css.table}>
              <table className={css.table}>
                <thead className={css.thead}>
                  <tr className={css.tr}>
                    <th scope="col" className={css.th}>
                      Seleccionar
                    </th>
                    <th scope="col" className={css.th}>
                      Nombre
                    </th>
                    <th scope="col" className={css.th} style={{ textAlign: 'center' }}>
                      Perfiles en uso
                    </th>
                  </tr>
                </thead>
                <tbody className={css.tbody}>
                  {list &&
                    list.map((item, key) => {
                      return (
                        <tr key={key} className={css.tr}>
                          <td className={css.tdCheck}>
                            {challenges && challenges.length <= 10 ? (
                              <label className={css.formcontrol}>
                                <input
                                  type="checkbox"
                                  id={`checkbox-${item.id}`}
                                  onChange={() => handleOnChange(key)}
                                  checked={checkedState[key]}
                                  disabled={pastCheckedState[key] === 'true' ? true : false}
                                />
                              </label>
                            ) : (
                              <label className={css.formcontrol}>
                                <input type="checkbox" id={`checkbox-${item.id}`} />
                              </label>
                            )}
                          </td>
                          <td className={css.titleCol}>
                            <span className={css.name}>{item.name}</span>
                            <p className={css.description}>{item.description}</p>
                          </td>

                          <td className={(css.td, css.roleCount)}>{item.roles_count}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <div className={css.tableFooter}>
              {list && list.length > 0 ? (
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    {meta && (
                      <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                        <PaginationLink
                          href={meta.first_page_url}
                          onClick={previous}
                          tabIndex={!meta.previous_page_url ? '-1' : undefined}
                        >
                          <FontAwesomeIcon icon={faAngleLeft}/>
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {pages &&
                      pages.map((item, key) => {
                        if (meta.current_page <= 5 && key < 9) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }

                        if (meta.current_page > 5) {
                          if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                            return (
                              <PaginationItem
                                key={key}
                                className={meta.current_page === item ? 'active' : ''}
                              >
                                <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                  {item}
                                  {meta.current_page === item && (
                                    <span className="sr-only">(current)</span>
                                  )}
                                </PaginationLink>
                              </PaginationItem>
                            )
                          }
                        }

                        if (meta.last_page - meta.current_page < 5) {
                          if (key > meta.last_page - 10) {
                            return (
                              <PaginationItem
                                key={key}
                                className={meta.current_page === item ? 'active' : ''}
                              >
                                <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                  {item}
                                  {meta.current_page === item && (
                                    <span className="sr-only">(current)</span>
                                  )}
                                </PaginationLink>
                              </PaginationItem>
                            )
                          }
                        }
                      })}
                    {meta && (
                      <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                        <PaginationLink href={meta.last_page_url} onClick={next}>
                          <FontAwesomeIcon icon={faAngleRight}/>
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    )}
                  </Pagination>
                </nav>
              ) : (
                <div className={css.noData}>
                  <p>Sin retos</p>
                </div>
              )}
              <div className={css.pagination}>
                <Button
                  className={css.delete}
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancelar
                </Button>
                {register && (
                  <Button type="button" onClick={register} className={css.registerButton} disabled={changed}>
                    Agregar
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react';

import classNames from 'classnames';
import ChallengeAvatar from '../../assets/perfil-01.png';

import css from './ChallengeCard.module.css'
import useDynamicTitle from '../../hooks/useDynamicTitle';

const ChallengeCard = props => {
    const {
        index,
        className,
        challenge,
        disabled,
        onClick,
        maxSequence
    } = props;

    // Dynamic title declaration hook
    const { dynamicTitle } = useDynamicTitle(challenge.sequence, maxSequence)

    // Class definition
    const classes = classNames(css.card, className, {
        [css.pending]: challenge.status === 'pending',
        [css.disabled]: disabled === true,
    });

    var challengeModule = challenge?.challengesModule?.length > 0 ? challenge.challengesModule[0] : {}

    return (
        <div 
            key={index}
            onClick={onClick} 
            className={classes}
        >
            <div className={css.content}>
                {/* Avatar del reto*/}
                <img alt="" src={challengeModule.url_image && challengeModule.url_image !== null ? challengeModule.url_image : ChallengeAvatar} className={css.img} />
                <div className={css.container}>
                    {/* Título de la tarjeta */}
                    <h6 className={css.title}>
                    { dynamicTitle }. { challengeModule.name }
                    </h6>
                    {/* Descripción del reto*/}
                    <h6 className={css.subtitle}>
                        {challengeModule.description}
                    </h6>
                    {/* Status del reto*/}
                    {challenge.status === 'pending' && (
                        <h6 className={css.pendingText}>Pendiente</h6>
                    )}
                    {challenge.status === 'in_progress' && (
                        <h6 className={css.inProgressText}>En Progreso</h6>
                    )}
                    {challenge.status === 'complete' && (
                        <h6 className={css.completeText}>Completo</h6>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChallengeCard;
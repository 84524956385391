import Axios from 'axios'

import {
  getAllRolesURL,
  getRolesUpdateURL,
  getRolesURL,
  getTypeformURL,
  getAddChallengesRoleURL,
  getChallengesByRoleURL,
  getUpdateChallengesURL,
  getRolesCreateURL,
  getRolesUpdateRoleURL ,
  getRolesByIdURL,
  getDeleteChallengeURL,
  getRemoveRoleURL,
  getFindProfileURL,
} from '../../const/Constants'

let instance = Axios.create({
  baseURL: `${process.env.REACT_APP_CMS_API}api/v2`,
})

export async function getRolesById(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getRolesByIdURL(page) )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getRoles(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getRolesURL(page) )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getAllRoles() {
  return new Promise((resolve, reject) => {
    instance
      .get(getAllRolesURL())
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function storeRole(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getRolesCreateURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function updateRol(roleId, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(getRolesUpdateRoleURL(roleId), data)           
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getTypeform(roleId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getTypeformURL(roleId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function showRole(roleId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getRolesByIdURL(roleId) )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function deleteRole(rolId, data) {
  return new Promise((resolve, reject) => {
    instance
      .delete(getRolesUpdateURL+ `/delete-role/${rolId}`, data)  
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function addChallenges(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getAddChallengesRoleURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getChallengesByRole(id) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengesByRoleURL(id))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getUpdateChallenges(data) {
  return new Promise((resolve, reject) => {
    instance
      .put(getUpdateChallengesURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

//roles/delete-challenge-in-role/:challengeRoleId
export async function deleteChallengeRole(id, data) { 
  return new Promise((resolve, reject) => { 
    instance
    .delete(getDeleteChallengeURL(id), data)
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => { 
      reject(error.response.data)
    })
  })
}

export async function removeRoleById(id, data){ 
  return new Promise((resolve, reject) => {
    instance
    .put(getRemoveRoleURL(id), data)
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => { 
      reject(error.response.data)
    })
  })
}

export async function getFindProfile(id, page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getFindProfileURL(id, page))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
import React from "react";
import { checkUser, login as loginApiService } from "../service/module/Auth";
import { showUser } from "../service/module/Users";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const MainContext = React.createContext();

export function MainProvider(props) {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [imageProfile, setImageProfile] = React.useState();
  const [userId, setUserId] = React.useState();
  const [userType, setUserType] = React.useState();
  const [userInfo, setUserInfo] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [reintent, setReintent] = React.useState(true);

  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const history = useHistory();

  const getUserInfo = async (userParam) => {
    try {
      await showUser(userParam).then((response) => {
        setUserInfo(response.data[0]);
      });
    } catch (e) {
      console.error(e.message);
    }
  }

  const loginApi = React.useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const accessToken = await getAccessTokenSilently();
      await loginApiService(accessToken)
        .then((response) => {
          const userType = response.user_type;
          setUserType(userType);
          setImageProfile(response.image_profile);
          setOpenBackdrop(false);
          setUserId(response.user_id);
          getUserInfo(response.user_id);
          if (user.email_verified) {
            history.push("/challenges");
          } else {
            localStorage.setItem('email', user.email)
            history.push("/verify-email");
          }
        })
        .catch((e) => {
          setOpenBackdrop(false);
          if (reintent) {
            const accessToken = getAccessTokenSilently();
            loginApiService(accessToken);
            setReintent(false);
          }
        });
    } catch (e) {
      console.error(e.message);
    }
  }, [getAccessTokenSilently, history, isAuthenticated, reintent]);

  const login = React.useCallback(() => {
    loginWithRedirect({ auto_login: false });
  }, [loginWithRedirect]);

  React.useEffect(() => {
    loginApi();
  }, [loginApi]);

  const value = React.useMemo(() => {
    return {
      user,
      userInfo,
      login,
      isAuthenticated,
      logout,
      imageProfile,
      setImageProfile,
      openBackdrop,
      userId,
      userType,
      handleDrawerToggle,
      mobileOpen,
      setOpenBackdrop,
    };
  }, [
    user,
    userInfo,
    login,
    isAuthenticated,
    logout,
    imageProfile,
    openBackdrop,
    userId,
    userType,
    handleDrawerToggle,
    mobileOpen,
    setOpenBackdrop,
  ]);

  return <MainContext.Provider value={value} {...props} />;
}

export function useProfile() {
  const context = React.useContext(MainContext);
  if (!context) {
    throw new Error("useProfile must be within the MainContext");
  }

  return context;
}


import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Header from '../../components/modules/dashboard/partials/Header'
import ChallengesModal from '../../components/modules/dashboard/roles/ChallengesModal'
import { addChallenges, getChallengesByRole } from '../../service/module/Roles'
import { getFindChallenge } from '../../service/module/Challenges'
import { useProfile } from '../../context/MainContext'

export default function AddChallengesRole() {
  const [errors, setErrors] = React.useState()
  const history = useHistory()
  const { id } = useParams()
  const [challenges, setChallenges] = React.useState({})
  const [challengesRole, setChallengesRole] = React.useState({})
  const [challengesFound, setChallengesFound] = React.useState()
  const [word, setWord] = React.useState()
  const { userId } = useProfile()
  const[changed, setChanged] = React.useState(true)
  const [registerChange, setRegisterChange] = React.useState(false)


  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getAllChallenges(id) {
      await getChallengesByRole(id)
        .then(response => {
          setChallengesRole(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (id) {
      getAllChallenges(id)
    }
  }, [id])

  async function register() {
    challenges.userId = userId
    setChanged(true)
    setRegisterChange(true)
    await addChallenges(challenges)
      .then(response => {
        if(response.success){ 
          Swal.fire({
            title: '¡Retos asignados exitosamente!',
            icon: 'success',
            confirmButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/roles/${id}/edit`)
          })
        }else{ 
          setErrors(response.message)
          Swal.fire({
            title: 'Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            confirmButtonText: 'Regresar',
          }).then(_result => {
            history.push(`/dashboard/roles/${id}/edit`)
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/roles/${id}/edit`)
        })
      })
  }

  function selectedOption(e) {
    setChallenges({
      role_id: id,
      challenges: e,
    })
  }

  function changeInput(e) {
    setWord(e.target.value)
  }

  function search() {
    async function getFindChallenges(id) {
      await getFindChallenge(id)
        .then(response => {
          setChallengesFound(response.data)
        })
        .catch(error => {
          console.error(error)
        })
    }

    getFindChallenges(word)
  }

  return (
    <div>
      <Header />
      <ChallengesModal
        title="Agregar Retos al Perfil"
        role={id}
        selectedOption={selectedOption}
        register={register}
        changeInput={changeInput}
        challengesFound={challengesFound}
        word={word}
        changed = {changed}
        setChanged = {setChanged}
        registerChanged={ registerChange}
      />
    </div>
  )
}
